<template>
  <div></div>
</template>

<script>
export default {
  name: 'useCrisp',
  props: {
    email: {
      type: String,
      default: null,
    },
    groupName: {
      type: String,
      default: null,
    },
    userRegistered: {
      type: Boolean,
      default: null,
    },
  },
  mounted() {
    console.warn('CRISP mounted');
    const crispJs = document.createElement('script');
    crispJs.setAttribute('src', '/crisp.js');
    crispJs.addEventListener('load', () => {
      if (this.email) window.$crisp.push(['set', 'user:email', [this.email]]);
      if (this.groupName) {
        window.$crisp.push(['set', 'session:data', ['last_group', this.groupName]]);
      }
    });
    document.head.appendChild(crispJs);
  },
};
</script>

<style scoped>

</style>
